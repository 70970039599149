<template>
  <svg class="search_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.7 20.3L18 16.6C19.4 14.8 20.1 12.5 19.9 10.2 19.8 7.9 18.7 5.8 17 4.3 15.3 2.7 13 1.9 10.7 2 8.4 2 6.2 3 4.6 4.6 3 6.2 2 8.4 2 10.7 1.9 13 2.7 15.3 4.3 17 5.8 18.7 7.9 19.8 10.2 19.9 12.5 20.1 14.8 19.4 16.6 18L20.3 21.7C20.4 21.8 20.5 21.8 20.6 21.9 20.7 22 20.9 22 21 22 21.1 22 21.3 22 21.4 21.9 21.5 21.8 21.6 21.8 21.7 21.7 21.9 21.5 22 21.2 22 21 22 20.7 21.9 20.5 21.7 20.3ZM11 18C9.6 18 8.3 17.6 7.1 16.8 6 16.1 5.1 15 4.5 13.7 4 12.4 3.9 11 4.1 9.6 4.4 8.3 5.1 7 6.1 6.1 7 5.1 8.3 4.4 9.6 4.1 11 3.9 12.4 4 13.7 4.5 15 5.1 16.1 6 16.8 7.1 17.6 8.3 18 9.6 18 11 18 12.9 17.3 14.6 15.9 16 14.6 17.3 12.9 18 11 18Z"/></svg>
</template>

<script>
export default {
  name: 'SearchIco'
}
</script>

<style lang="stylus">

.search_icon
  width 20px
  height auto

</style>
